// Menu Toggle
var menuToggle = function (event) {

 if (!event.target.matches('.mobileMenuToggle')) return;
 document.querySelector('body').classList.toggle('navOpen');
 if(!event.target.matches('.mobileContact')){
  event.preventDefault();
 }

};

document.addEventListener('click', function (event) {
  menuToggle(event);
});

// Form processing with recaptcha and messaging
var contactForm = document.getElementById('contactForm');

if(contactForm){
  contactForm.addEventListener('submit', function (event) {
    event.preventDefault();
    document.querySelector('.submitButton').disabled = true;
    grecaptcha.reset();
    grecaptcha.execute();
  });
}

function formSubmit(response) {
  document.getElementById('contactForm').submit();
}
// End: Form processing with recaptcha and messaging

function scrollToInvalid(id) {

  var navHeight = 108;
  var invalid_el = document.querySelector('#' + id + '');
  var invalid_position = invalid_el.offsetTop + navHeight;

  if ( invalid_el > (window.pageYOffset - navHeight) && invalid_el < (window.pageYOffset + window.innerHeight - navHeight) ) {
    return true;
  } else {
    window.scroll({ top: invalid_position, left: 0 });
  }

}

// --- TOASTER --- //

// Look for a message parameter
var message = getParameterByName("message");

if(message === 'failed'){
  messageToaster('', 'There has been a problem, please try again...', 'failed');
}
if(message === 'success'){
  messageToaster('', 'Your message has been sent.', 'success');
}

// Create our message timer
var messagetimer;

function messageToaster(title, message, type) {

  clearTimeout(messagetimer);

  var toaster = document.querySelector('.toaster');

  clearToaster(toaster);

  if(type === 'failed'){
    toaster.classList.add('failed');
  } else {
    toaster.classList.add('success');
  }

  document.querySelector('.toaster__content').innerText = message;
  toaster.classList.add('open');

  messagetimer = setTimeout(function() {
      toaster.classList.remove('open');
      window.history.replaceState(null, null, window.location.pathname);
  }, 8000);

}

function clearToaster(toaster) {
  toaster.classList.remove('failed', 'success');
}

// First at the URL to target correct message
function getParameterByName(name, url) {
    if (!url) {
        url = window.location.href;
    }
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

// --- TOASTER END --- //